import axios from "axios";
import _ from 'lodash'
import groupArray from 'group-array'
import socketIOClient from "socket.io-client";

const socket = window.location.origin.includes('https://bim.wohhup.com') ?
  socketIOClient(window.location.origin, { transports: ['websocket'] }) : socketIOClient(window.location.origin)



/**
 * Calculate Quantity
 * @customfunction 
 * @param {string} cipRoomNumber Second number
 * @param {string} cipContract Second number
 * @param {string} cipActivity Second number
 * @returns {string} qtt.
 */

async function qtt(cipRoomNumber, cipContract, cipActivity) {
  try {
    socket.on('realtime-excel', (data) => {
      console.log(data)
    })
    
    if (cipRoomNumber === '' || cipContract === '' || cipActivity === '') return ''
    let context = new Excel.RequestContext();

    let sheetProductivity = context.workbook.worksheets.getItemOrNullObject("Productivity");
    let productivityTable = sheetProductivity.tables.getItem("productivityTable");

    let sheetFactor = context.workbook.worksheets.getItemOrNullObject("Factor");
    let factorTable = sheetFactor.tables.getItem("factorTable");

    let sheetDuctFactor = context.workbook.worksheets.getItemOrNullObject("Duct Factor");
    let ductFactorTable = sheetDuctFactor.tables.getItem("ductFactorTable");

    if (window.sharedData) {
      await context.sync()
      if (productivityTable.isNull || factorTable.isNull || ductFactorTable.isNull) return 'Error'
      let rangeProductivity = productivityTable.getDataBodyRange().load("values");
      let rangeDuctFactor = ductFactorTable.getDataBodyRange().load("values");
      let rangeFactor = factorTable.getDataBodyRange().load("values");
      rangeProductivity.load("values");
      rangeDuctFactor.load("values");
      rangeFactor.load("values");
      await context.sync()
      let qtt = getQTT(window.sharedData, cipRoomNumber, cipContract, cipActivity, rangeFactor.values, rangeDuctFactor.values)

      _.forEach(qtt, (installationValue, installationKey) => {
        _.forEach(installationValue, (materialValue, materialKey) => {
          _.forEach(materialValue, (unitValue, unitKey) => {
            _.forEach(unitValue, (connectionTypeValue, connectionTypeKey) => {
              _.forEach(connectionTypeValue, (methodValue, methodKey) => {
                _.forEach(methodValue, (lookupValue, lookupKey) => {
                  let result = {}
                  _.forEach(lookupValue, (v, k) => {
                    var numb = v.value;
                    numb = +numb.toFixed(3);
                    result[k] = numb
                  })
                  methodValue[lookupKey] = result
                })
              })
            })
          })
        })
      })
      return JSON.stringify(qtt)
    } else {

      let sheet = context.workbook.worksheets.getItemOrNullObject("Project Data");
      let expensesTable = sheet.tables.getItem("projectDataTable");


      await context.sync()
      if (expensesTable.isNull || productivityTable.isNull || factorTable.isNull || ductFactorTable.isNull) return 'Error'
      let range = expensesTable.getDataBodyRange().load("values");
      let rangeProductivity = productivityTable.getDataBodyRange().load("values");
      let rangeDuctFactor = ductFactorTable.getDataBodyRange().load("values");
      let rangeFactor = factorTable.getDataBodyRange().load("values");
      // Read the range address
      range.load("values");
      rangeProductivity.load("values");
      rangeDuctFactor.load("values");
      rangeFactor.load("values");
      await context.sync()

      let data = range.values.slice(0)
      let temp = []
      let projectId = ''
      _.forEach(data, (v, k) => {
        if (v[0] === 'Project') {
          projectId = v[v.length - 1]
        } else if (v[0] === 'File' && v[0] !== '') {
          temp.push(v[v.length - 1])
        }
      })
      let resList = [];
      _.forEach(temp, async v => {
        let response = axios.get('/api/items/get-excel-default-version', { params: { projectId: projectId, itemId: v } })
        resList.push(response);
      })
      let itemFiles = await Promise.all(resList);
      resList = [];
      _.forEach(itemFiles, v => {
        let response = axios.get(`/api/forge/modelderivative/metadatas/${v.data.objectId}/{3D}`)
        resList.push(response);
      })

      let response = await Promise.all(resList);
      let all = []
      _.forEach(response, item => {
        let dic = [];
        let tempObj = getAllObject(item.data.object)
        _.forEach(tempObj, (v, k) => {
          let index = _.findIndex(item.data.properties, function (o) { return o.objectid === v.objectid });
          if (index >= 0) {
            let tempProps = {}
            _.forEach(item.data.properties[index].properties, p => {
              _.forEach(p, (o, k) => {
                tempProps[k] = o
              })
            })
            item.data.properties[index].properties = tempProps
            all.push(item.data.properties[index])
          }
        })
      })
      // console.log(all)
      let group = groupArray(all, 'properties.CIP_Room Number', 'properties.CIP_Contract', 'properties.CIP_Activity', 'properties.CIP_Installation Type', 'properties.CIP_Material', 'properties.CIP_Unit',
        'properties.CIP_Connection Type', 'properties.CIP_Method of Installation', 'properties.CIP_Lookup');
      window.sharedData = group
      console.log(group)
      let qtt = getQTT(group, cipRoomNumber, cipContract, cipActivity, rangeFactor.values, rangeDuctFactor.values)
      _.forEach(qtt, (installationValue, installationKey) => {
        _.forEach(installationValue, (materialValue, materialKey) => {
          _.forEach(materialValue, (unitValue, unitKey) => {
            _.forEach(unitValue, (connectionTypeValue, connectionTypeKey) => {
              _.forEach(connectionTypeValue, (methodValue, methodKey) => {
                _.forEach(methodValue, (lookupValue, lookupKey) => {
                  let result = {}
                  _.forEach(lookupValue, (v, k) => {
                    var numb = v.value;
                    numb = +numb.toFixed(3);
                    result[k] = numb
                  })
                  methodValue[lookupKey] = result
                })
              })
            })
          })
        })
      })

      return JSON.stringify(qtt)
    }
  }
  catch (e) {
    console.log(e)
  }
}

/**
 * Calculate Productivity
 * @customfunction 
 * @param {string} cipRoomNumber Second number
 * @param {string} cipContract Second number
 * @param {string} cipActivity Second number
 * @returns {string} productivity.
 */

async function productivity(cipRoomNumber, cipContract, cipActivity) {
  try {
    if (cipRoomNumber === '' || cipContract === '' || cipActivity === '') return ''


    let context = new Excel.RequestContext();

    let sheetProductivity = context.workbook.worksheets.getItemOrNullObject("Productivity");
    let productivityTable = sheetProductivity.tables.getItem("productivityTable");

    let sheetFactor = context.workbook.worksheets.getItemOrNullObject("Factor");
    let factorTable = sheetFactor.tables.getItem("factorTable");

    let sheetDuctFactor = context.workbook.worksheets.getItemOrNullObject("Duct Factor");
    let ductFactorTable = sheetDuctFactor.tables.getItem("ductFactorTable");

    if (window.sharedData) {
      await context.sync()
      if (productivityTable.isNull || factorTable.isNull || ductFactorTable.isNull) return 'Error'
      let rangeProductivity = productivityTable.getDataBodyRange().load("values");
      let rangeDuctFactor = ductFactorTable.getDataBodyRange().load("values");
      let rangeFactor = factorTable.getDataBodyRange().load("values");
      rangeProductivity.load("values");
      rangeDuctFactor.load("values");
      rangeFactor.load("values");
      await context.sync()
      let qtt = getQTT(window.sharedData, cipRoomNumber, cipContract, cipActivity, rangeFactor.values, rangeDuctFactor.values)
      let end = ''
      let lookupTable = []
      _.forEach(rangeProductivity.values, o => {
        if (o[0] === '') return
        if (o[0] === cipActivity) {
          end = 'start'
          lookupTable.push({
            activity: o[0], installation: o[1], material: o[4], unit: o[6], connection: o[5], method: o[3],
            min: o[o.length - 2], max: o[o.length - 1], productivityValue: o[o.length - 3]
          })
        } else if (end === 'start') {
          end = 'end'
        } else if (end === 'end') {
          return false
        }
      })
      window.tempLookupTable = lookupTable.splice(0)
      _.forEach(qtt, (installationValue, installationKey) => {
        _.forEach(installationValue, (materialValue, materialKey) => {
          _.forEach(materialValue, (unitValue, unitKey) => {
            _.forEach(unitValue, (connectionTypeValue, connectionTypeKey) => {
              _.forEach(connectionTypeValue, (methodValue, methodKey) => {
                _.forEach(methodValue, (lookupValue, lookupKey) => {
                  let result = {}
                  _.forEach(lookupValue, (v, k) => {
                    result[k] = 1
                  })
                  _.forEach(lookupValue, (v, k) => {
                    _.forEach(window.tempLookupTable, o => {
                      if (o.activity === cipActivity && o.installation === installationKey && o.material === materialKey && o.unit === unitKey && o.connection === connectionTypeKey && o.method === methodKey) {
                        if (lookupKey === 'Size') {
                          if (o.min <= v.lookup && v.lookup <= o.max) {
                            let numb = o.productivityValue * v.factor
                            numb = +numb.toFixed(3);
                            result[k] = numb
                          }
                        } else {
                          let numb = o.productivityValue * v.factor
                          numb = +numb.toFixed(3);
                          result[k] = numb
                        }
                      }
                    })
                  })
                  methodValue[lookupKey] = result
                })
              })
            })
          })
        })
      })
      return JSON.stringify(qtt)
    } else {

      let sheet = context.workbook.worksheets.getItemOrNullObject("Project Data");
      let expensesTable = sheet.tables.getItem("projectDataTable");


      await context.sync()
      if (expensesTable.isNull || productivityTable.isNull || factorTable.isNull || ductFactorTable.isNull) return 'Error'
      let range = expensesTable.getDataBodyRange().load("values");
      let rangeProductivity = productivityTable.getDataBodyRange().load("values");
      let rangeDuctFactor = ductFactorTable.getDataBodyRange().load("values");
      let rangeFactor = factorTable.getDataBodyRange().load("values");
      // Read the range address
      range.load("values");
      rangeProductivity.load("values");
      rangeDuctFactor.load("values");
      rangeFactor.load("values");
      await context.sync()

      let data = range.values.slice(0)
      let temp = []
      let projectId = ''
      _.forEach(data, (v, k) => {
        if (v[0] === 'Project') {
          projectId = v[v.length - 1]
        } else if (v[0] === 'File' && v[0] !== '') {
          temp.push(v[v.length - 1])
        }
      })
      let resList = [];
      _.forEach(temp, async v => {
        let response = axios.get('/api/items/get-excel-default-version', { params: { projectId: projectId, itemId: v } })
        resList.push(response);
      })
      let itemFiles = await Promise.all(resList);
      resList = [];
      _.forEach(itemFiles, v => {
        let response = axios.get(`/api/forge/modelderivative/metadatas/${v.data.objectId}/{3D}`)
        resList.push(response);
      })

      let response = await Promise.all(resList);
      let all = []
      _.forEach(response, item => {
        let dic = [];
        let tempObj = getAllObject(item.data.object)
        _.forEach(tempObj, (v, k) => {
          let index = _.findIndex(item.data.properties, function (o) { return o.objectid === v.objectid });
          if (index >= 0) {
            let tempProps = {}
            _.forEach(item.data.properties[index].properties, p => {
              _.forEach(p, (o, k) => {
                tempProps[k] = o
              })
            })
            item.data.properties[index].properties = tempProps
            all.push(item.data.properties[index])
          }
        })
      })
      // console.log(all)
      let group = groupArray(all, 'properties.CIP_Room Number', 'properties.CIP_Contract', 'properties.CIP_Activity', 'properties.CIP_Installation Type', 'properties.CIP_Material', 'properties.CIP_Unit',
        'properties.CIP_Connection Type', 'properties.CIP_Method of Installation', 'properties.CIP_Lookup');
      window.sharedData = group
      console.log(group)
      let qtt = getQTT(group, cipRoomNumber, cipContract, cipActivity, rangeFactor.values, rangeDuctFactor.values)
      let end = ''
      let lookupTable = []
      _.forEach(rangeProductivity.values, o => {
        if (o[0] === '') return
        if (o[0] === cipActivity) {
          end = 'start'
          lookupTable.push({
            activity: o[0], installation: o[1], material: o[4], unit: o[6], connection: o[5], method: o[3],
            min: o[o.length - 2], max: o[o.length - 1], productivityValue: o[o.length - 3]
          })
        } else if (end === 'start') {
          end = 'end'
        } else if (end === 'end') {
          return false
        }
      })
      window.tempLookupTable = lookupTable.splice(0)
      _.forEach(qtt, (installationValue, installationKey) => {
        _.forEach(installationValue, (materialValue, materialKey) => {
          _.forEach(materialValue, (unitValue, unitKey) => {
            _.forEach(unitValue, (connectionTypeValue, connectionTypeKey) => {
              _.forEach(connectionTypeValue, (methodValue, methodKey) => {
                _.forEach(methodValue, (lookupValue, lookupKey) => {
                  let result = {}
                  _.forEach(lookupValue, (v, k) => {
                    result[k] = 1
                  })
                  _.forEach(lookupValue, (v, k) => {
                    _.forEach(window.tempLookupTable, o => {
                      if (o.activity === cipActivity && o.installation === installationKey && o.material === materialKey && o.unit === unitKey && o.connection === connectionTypeKey && o.method === methodKey) {
                        if (lookupKey === 'Size') {
                          if (o.min <= v.lookup && v.lookup <= o.max) {
                            let numb = o.productivityValue * v.factor
                            numb = +numb.toFixed(3);
                            result[k] = numb
                          }
                        } else {
                          let numb = o.productivityValue * v.factor
                          numb = +numb.toFixed(3);
                          result[k] = numb
                        }
                      }
                    })
                  })
                  methodValue[lookupKey] = result
                })
              })
            })
          })
        })
      })
      return JSON.stringify(qtt)
    }
  }
  catch (e) {
    console.log(e)
  }
}
/**
 * Calculate Man Hours
 * @customfunction 
 * @param {string} qtt Second number
 * @param {string} productivity Second number
 * @returns {number} manhours.
 */

async function manhours(qtt, productivity) {
  try {
    let total = 0
    if (qtt === '' || productivity === '') return 0
    let qttParse = JSON.parse(qtt)
    let productivityParse = JSON.parse(productivity)
    if (_.isObject(qttParse) && _.isObject(productivityParse)) {
      _.forEach(qttParse, (installationValue, installationKey) => {
        _.forEach(installationValue, (materialValue, materialKey) => {
          _.forEach(materialValue, (unitValue, unitKey) => {
            _.forEach(unitValue, (connectionTypeValue, connectionTypeKey) => {
              _.forEach(connectionTypeValue, (methodValue, methodKey) => {
                _.forEach(methodValue, (lookupValue, lookupKey) => {
                  _.forEach(lookupValue, (v, k) => {
                    if (productivityParse[installationKey][materialKey][unitKey][connectionTypeKey][methodKey][lookupKey][k]) {
                      total = total + v * productivityParse[installationKey][materialKey][unitKey][connectionTypeKey][methodKey][lookupKey][k]
                    } else {
                      total = total + v
                    }
                  })
                })
              })
            })
          })
        })
      })
      return total
    }
    return 0
  }
  catch (e) {
    console.log(e)
  }
}
function getQTT(group, cipRoomNumber, cipContract, cipActivity, rangeFactor, rangeDuctFactor) {
  let check = true
  let tempInstallation = {}
  _.forEach(group, (roomNameValue, roomNameKey) => {
    if (roomNameKey === cipRoomNumber && check) {
      _.forEach(roomNameValue, (contractValue, contractKey) => {
        if (contractKey === cipContract && check) {
          _.forEach(contractValue, (activityValue, activityKey) => {
            if (activityKey === cipActivity && check) {
              _.forEach(activityValue, (installationValue, installationKey) => {
                tempInstallation[installationKey] = {}
                _.forEach(installationValue, (materialValue, materialKey) => {
                  tempInstallation[installationKey][materialKey] = {}
                  _.forEach(materialValue, (unitValue, unitKey) => {
                    tempInstallation[installationKey][materialKey][unitKey] = {}
                    _.forEach(unitValue, (connectionTypeValue, connectionTypeKey) => {
                      tempInstallation[installationKey][materialKey][unitKey][connectionTypeKey] = {}
                      _.forEach(connectionTypeValue, (methodValue, methodKey) => {
                        tempInstallation[installationKey][materialKey][unitKey][connectionTypeKey][methodKey] = {}
                        _.forEach(methodValue, (lookupValue, lookupKey) => {
                          tempInstallation[installationKey][materialKey][unitKey][connectionTypeKey][methodKey][lookupKey] = {}
                          let dictionary = {}
                          _.forEach(lookupValue, (object) => {
                            let factor = 1
                            let ductFactor = 1
                            if (object.properties['CIP_Installation Height']) {
                              let offset = _.toNumber(object.properties['CIP_Installation Height']) / 1000
                              _.forEach(rangeFactor, f => {
                                if (f[0] <= offset)
                                  factor = f[1]
                              })
                            }
                            if (cipActivity === 'Duct Work Installation') {
                              if (object.properties['CIP_DuctWork Classification']) {
                                _.forEach(rangeDuctFactor, f => {
                                  if (f[0] === object.properties['CIP_DuctWork Classification'])
                                    ductFactor = f[1]
                                })
                              }
                            }
                            if (lookupKey === 'Name') {
                              if (object.properties['Type Name']) {
                                let number = 0
                                if (!dictionary[object.properties['Type Name']])
                                  dictionary[object.properties['Type Name']] = { lookup: number, value: 0, factor: factor * ductFactor }
                                if (unitKey === 'per m2') {
                                  if (object.properties['Area']) {
                                    let split = object.properties['Area'].split(' ')
                                    let toNumber = _.toNumber(split[0])
                                    if (_.isNumber(toNumber))
                                      dictionary[object.properties['Type Name']].value = dictionary[object.properties['Type Name']].value + toNumber
                                  }
                                } else if (unitKey === 'per m3') {
                                  if (object.properties['Volume']) {
                                    let split = object.properties['Volume'].split(' ')
                                    let toNumber = _.toNumber(split[0])
                                    if (_.isNumber(toNumber))
                                      dictionary[object.properties['Type Name']].value = dictionary[object.properties['Type Name']].value + toNumber
                                  }
                                } else if (unitKey === 'per kg') {
                                  if (object.properties['CIP_Weight']) {
                                    let split = object.properties['CIP_Weight'].split(' ')
                                    let toNumber = _.toNumber(split[0])
                                    if (_.isNumber(toNumber))
                                      dictionary[object.properties['Type Name']].value = dictionary[object.properties['Type Name']].value + toNumber
                                  }
                                } else if (unitKey === 'per item') {
                                  dictionary[object.properties['Type Name']].value += 1
                                }

                              }
                            } else if (lookupKey === 'Size' && unitKey === 'per item') {
                              let value = object.properties[lookupKey]
                              let x = value.replace(/x/g, " ")
                              let symbol = x.replace(/-/g, " ")
                              let split = symbol.split(' ')
                              let number = 0
                              _.forEach(split, lookNumb => {
                                let tempLookNum = _.toNumber(lookNumb)
                                if (_.isNumber(tempLookNum)) {
                                  if (number <= tempLookNum)
                                    number = tempLookNum
                                }
                              })
                              if (!dictionary[value])
                                dictionary[value] = { lookup: number, value: 0, factor: factor * ductFactor }
                              dictionary[value].value += 1
                            } else {
                              if (object.properties[lookupKey]) {
                                let number = 0
                                let value = object.properties[lookupKey]
                                if (unitKey === 'per meter') {
                                  let split = value.split('x')
                                  _.forEach(split, v => {
                                    let subSplit = v.split(' ')
                                    let tempNumber = _.toNumber(subSplit[0])
                                    if (_.isNaN(tempNumber))
                                      tempNumber = 0
                                    if (tempNumber > number)
                                      number = tempNumber
                                  })
                                  if (!dictionary[value]) {
                                    dictionary[value] = { lookup: number, value: 0, factor: factor * ductFactor }
                                  }
                                } else {
                                  if (!dictionary[value]) {
                                    dictionary[value] = { lookup: number, value: 0, factor: factor * ductFactor }
                                  }
                                }
                                if (unitKey === 'per meter') {
                                  if (object.properties['Length']) {
                                    let split = object.properties['Length'].split(' ')
                                    let length = _.toNumber(split[0])
                                    dictionary[value].value = dictionary[value].value + (length === 0 ? length : length / 1000)
                                  }
                                } else if (unitKey === 'per m2') {
                                  if (object.properties['Area']) {
                                    let split = object.properties['Area'].split(' ')
                                    dictionary[value].value = dictionary[value].value + _.toNumber(split[0])
                                  }
                                } else if (unitKey === 'per m3') {
                                  if (object.properties['Volume']) {
                                    let split = object.properties['Volume'].split(' ')
                                    dictionary[value].value = dictionary[value].value + _.toNumber(split[0])
                                  }
                                } else if (unitKey === 'per kg') {
                                  if (object.properties['CIP_Weight']) {
                                    let split = object.properties['CIP_Weight'].split(' ')
                                    dictionary[value].value = dictionary[value].value + _.toNumber(split[0])
                                  }
                                } else if (unitKey === 'per item') {
                                  let number = dictionary[value].value
                                  dictionary[value].value = number + 1
                                }
                              }
                            }
                          })
                          tempInstallation[installationKey][materialKey][unitKey][connectionTypeKey][methodKey][lookupKey] = dictionary
                        })
                      })
                    })
                  })
                })
              })
            } else if (!check) {
              return false
            }
          })
        } else if (!check) {
          return false
        }
      })

    } else if (!check) {
      return false
    }
  })

  return tempInstallation
}
// function getQuantity(data) {
//   let temp={}
//   _.forEach(data, v => {
//     _.forEach(v.properties, property => {
//       let cipInstallation = ''
//       let value = 0
//       if (property.displayName === 'Category') {
//         category = property.displayValue
//         if (property.displayValue.includes('Revit')) {
//           category = property.displayValue.replace('Revit ', '')
//         }
//       }
//       else if (property.displayName === 'Length') {
//         value = property.displayValue
//       }
//     })
//   })
// }




// window.Office.onReady=()=>{
//   setTimeout(() => {
//     registerChangeEventHandler()
//   }, 1000);

// }

async function registerChangeEventHandler() {
  try {
    await window.Excel.run(async (context) => {
      const sheet = context.workbook.worksheets.getItemOrNullObject("Project Data");
      context.sync()
        .then(async (e) => {
          if (!sheet.isNull) {
            const table = sheet.tables.getItemOrNullObject("projectDataTable");
            if (table) {
              table.onChanged.add(onTableChanged);
              await context.sync();
              console.log("Added onChanged handler");
            }
          }
        })

    });
  } catch (e) {
    console.log(e)
  }

}
async function onTableChanged(eventArgs) {
  await window.Excel.run(async (context) => {
    const details = eventArgs.details;
    const address = eventArgs.address;

    console.log(
      `Change at ${address}: was ${details.valueBefore}(${details.valueTypeBefore}),` +
      ` now is ${details.valueAfter}(${details.valueTypeAfter})`
    );
  });
}

function getAllObject(data) {
  var temp = [];
  var queue = [];
  _.forEach(data, (v, k) => {
    queue.push(v);
  })

  while (queue.length > 0) {
    var node = queue.shift();
    if (node.objects !== undefined) {
      if (node.objects.length !== 0) {
        let childNode = []
        _.forEach(node.objects, (v, k) => {
          childNode.push(v)
        })
        queue = queue.concat(childNode)
      } else {
        temp.push(node);
      }
    } else {
      temp.push(node);
    }

  }
  return temp
}